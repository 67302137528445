pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}
code.hljs {
  padding: 3px 5px
}
/*

Style with support for rainbow parens

*/
.hljs {
  background: #474949;
  color: #d1d9e1
}
.hljs-comment,
.hljs-quote {
  color: #969896;
  font-style: italic
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-type,
.hljs-addition {
  color: #cc99cc
}
.hljs-number,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #f99157
}
.hljs-string,
.hljs-doctag,
.hljs-regexp {
  color: #8abeb7
}
.hljs-title,
.hljs-name,
.hljs-section,
.hljs-built_in {
  color: #b5bd68
}
.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-title.class_,
.hljs-class .hljs-title {
  color: #ffcc66
}
.hljs-section,
.hljs-name,
.hljs-strong {
  font-weight: bold
}
.hljs-symbol,
.hljs-bullet,
.hljs-subst,
.hljs-meta,
.hljs-link {
  color: #f99157
}
.hljs-deletion {
  color: #dc322f
}
.hljs-formula {
  background: #eee8d5
}
.hljs-attr,
.hljs-attribute {
  color: #81a2be
}
.hljs-emphasis {
  font-style: italic
}